<template>
	<div>
		<CCard>
			<CCardBody class="card-risks">
				<CustomTable :total-pages="totalPages" :total-items="totalItems" @search="search">
					<template #headers>
						<tr>
							<th
								v-for="(header, index1) in headers"
								:key="`${header.title}-${index1}`"
								scope="col"
								:class="`${header.class}`"
								>{{ header.title }}</th
							>
						</tr>
					</template>
					<template #body>
						<template v-if="riskList && riskList.length">
							<tr v-for="(item, index2) in riskList" :key="`${item.id}-${index2}`">
								<td scope="row" class="table-no">{{ item.date }}</td>
								<td scope="row" class="text-break text-pre-wrap">{{ getResult(item.result) }}</td>
								<td scope="row" class="fit text-center actions">
									<router-link
										:to="{
											name: 'CustomerResultDetail',
											params: { id: item.id },
										}"
										class="link-icon link-redirect"
									>
										<span class="prefix-input d-inline-block">
											<font-awesome-icon :icon="['fas', 'eye']" :title="$t('Action.Detail')" />
										</span>
									</router-link>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr class="text-center">
								<td colspan="12" class="fit">
									{{ $t("Table.NoData") }}
								</td>
							</tr>
						</template>
					</template>
				</CustomTable>
				<CRow class="text-center">
					<CCol>
						<button type="button" class="btn btn-outline-danger ml-2" @click="cancel">
							{{ $t("Button.Exit") }}
						</button>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>
<script>
import { GET_RISK_LIST } from "../store/action-types"
import { RESET_FILTERS_TABLE_DETAIL } from "../store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState, mapMutations } = createNamespacedHelpers("customers")
import { PAGING } from "../../../shared/plugins/constants"

export default {
	name: "RiskForm",

	props: {
		id: {
			type: [Number, String],
			default: null,
		},
	},

	data() {
		return {
			headers: [
				{
					title: this.$t("RiskForm.ResultDate"),
					class: "fit",
				},
				{
					title: this.$t("RiskForm.Result"),
					class: "text-nowrap name",
				},
				{
					title: this.$t("RiskForm.Actions"),
					class: "fit text-center",
				},
			],
			isCreateExamination: true,
			limit: PAGING.LIMIT,
			offset: PAGING.SKIP,
		}
	},

	computed: {
		...mapState(["riskList", "totalItems"]),

		totalPages() {
			return Math.ceil(this.totalItems / this.limit)
		},
	},

	created() {
		this.setData()
	},

	beforeDestroy() {
		this.RESET_FILTERS_TABLE_DETAIL()
	},

	methods: {
		...mapActions({
			GET_RISK_LIST,
		}),
		...mapMutations({ RESET_FILTERS_TABLE_DETAIL }),

		setData: async function () {
			const params = {
				limit: this.limit,
				offset: this.offset,
			}
			await this.GET_RISK_LIST({ params, id: this.id })
		},

		getNo(no) {
			return no + 1 + (+this.offset || 0)
		},

		getResult(getResult) {
			if (!getResult) return ""
			return this.$t(`RiskResult.${getResult}`)
		},

		search: async function (params = {}) {
			await this.GET_RISK_LIST({ params, id: this.id })
		},

		cancel: function () {
			this.$emit("cancel")
		},
	},
}
</script>

<style lang="scss">
.card-risks {
	padding: 0;
	padding-bottom: 1.25rem;

	.custom-table .card-body {
		padding-bottom: 0;
	}
}
</style>
